
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































































































































.tv-technology {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  width: 100%;
  padding: $spacing * 0.5 0 $spacing * 3;
  background: $c-cultured;

  @include mq(l) {
    padding: $spacing * 1.5 0 $spacing * 6;
  }
}

.tv-technology__title {
  @extend %text-center;

  margin: 0 auto $spacing * 1.5;

  ::v-deep strong {
    color: $c-pink-medium;
  }

  @include mq(l) {
    margin-bottom: $spacing * 3.5;
  }
}

.tv-technology__list {
  ::v-deep {
    .carousel__item {
      width: 75vw;

      @include mq(m) {
        flex: 1 1 0;
      }
    }
  }

  @include mq(m) {
    ::v-deep {
      .tv-default-slider__inner {
        display: flex;
        justify-content: space-between;
        gap: $spacing * 2;

        &::after {
          content: '';
        }
      }
    }
  }

  @include mq(xl) {
    ::v-deep {
      .tv-default-slider__inner {
        gap: $spacing * 3.5;
      }
    }
  }
}

.tv-technology__item {
  @extend %text-center;

  height: 100%;
  padding: $spacing * 1.25 $spacing;
  background: $white;
  border-radius: 1rem;
  box-shadow: 0 4px 10px rgba($black, 0.1);

  @include mq(l) {
    padding: $spacing * 2.5 $spacing * 1.75;
  }
}

.tv-technology__item__logo {
  position: relative;
  width: 100%;
  height: 5rem;
  margin: 0 auto $spacing;

  img {
    @include image-fit(contain);
  }

  @include mq(l) {
    height: 6rem;
    margin-bottom: $spacing * 1.5;
  }
}

.tv-technology__item__title {
  @extend %fw-normal;

  margin: $spacing * 0.5 auto 0;

  @include mq($until: l) {
    margin: $spacing * 0.5 0 0;

    ::v-deep br {
      content: '';

      &::after {
        content: ' ';
      }
    }
  }
}

.tv-technology__link {
  display: flex;
  width: fit-content;
  margin: $spacing * 1.5 auto 0;

  ::v-deep .btn__icon {
    margin-left: $spacing * 0.5;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  @include mq(l) {
    margin-top: $spacing * 5;
  }
}
