
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





















































































































































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */

.vod-slider,
[class*='vod-slider--'] {
  overflow: hidden;
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 2;
  position: relative;
}

.vod-slider-inner {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  min-height: 40rem;

  @include mq(l) {
    flex-direction: row;
  }
}

// LOADER
.vod-slider {
  .vod-slider__loader {
    position: absolute;
    z-index: 300;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $c-light-gray;
  }

  .vod-slider__loader__spiner {
    width: 20%;
    max-width: 15rem;
  }
}

// TODO REFACTOR

// Slidy related
.slidy-controls {
  position: absolute;
  z-index: 200;
  top: calc(((28 / 36) * 100vw) / 2.2);
  left: 0;
  display: flex;
  flex-direction: column;

  @include mq($until: 'l') {
    right: 0;
    width: 60% !important;
    margin: 0 auto;
  }

  @include mq($until: 's') {
    width: 100% !important;
  }

  @include mq('l') {
    top: auto;
    bottom: $spacing * 3;
    left: $spacing * 3.5;
  }

  @include mq('xl') {
    left: $spacing * 6;
  }
}

.slidy-controls__item,
[class*='slidy-controls__item--'] {
  @extend %button-nostyle;

  height: 5rem;

  svg {
    width: 4rem;
    height: 4rem;
    padding: $spacing / 1.5;
    border: 2px solid $c-light;
    border-radius: 100%;
    fill: $c-light;
    transition: 0.2s $ease-out-quad;
    transition-property: background-color, fill;

    @include mq(l) {
      width: 5rem;
      height: 5rem;
    }
  }

  &.is-disabled {
    opacity: 0.2;
  }

  & + & {
    margin-top: 1rem;
  }

  &:hover:not(.is-disabled) {
    svg {
      background-color: $white !important;
      fill: $c-anthracite-light;
    }
  }

  @include mq($until: 'l') {
    position: absolute;
  }

  // disable hover on touchdevice
  @media (hover: none) {
    &:hover:not(.is-disabled) {
      svg {
        background-color: transparent !important;
        fill: $c-light;
      }
    }
  }
}

[class*='slidy-controls__item--'][class*='--prev'] {
  left: $spacing;

  svg {
    transform: rotate(90deg);
  }
}

[class*='slidy-controls__item--'][class*='--next'] {
  right: $spacing;

  svg {
    transform: rotate(270deg);
  }

  @include mq(xl) {
    order: -1;
  }
}

::v-deep {
  .slidy-nav {
    @extend %list-nostyle;

    z-index: 9;
    display: none;

    @include mq(m) {
      top: -16rem;
    }

    @include mq('l') {
      position: absolute;
      top: auto;
      bottom: -3rem;
      left: calc(100% + 12rem);
      display: flex;
      align-items: center;
      padding: 3rem 0 2rem;
    }
  }

  .slidy-nav__item {
    button {
      @extend %button-nostyle;

      width: 33vw;
      height: 8rem;
      border: 1px solid rgba($c-light, 0.3);
      border-radius: 10px;
      transition: 0.7s $ease-out-circ;
      transition-property: background-color, color, opacity, transform;

      @include mq(m) {
        height: 12rem;
      }

      @include mq('l') {
        width: 26rem;
        height: 14rem;
      }
    }

    &:not(:first-child) {
      margin-left: $spacing;
    }
  }

  .slidy-nav__item__picture {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    margin: 0 auto;

    @include mq(m) {
      width: 100%;
    }
  }

  // State
  .slidy-nav__item.is-active {
    button {
      color: $c-light;
      background-color: $c-blue-dark;
      border: 0;
      box-shadow: 0 0 40px 0 rgba($c-blue-contrast, 0.3);
    }
  }

  .slidy-nav__item.is-seen {
    button {
      @include mq('l') {
        // opacity: 0;
        transform: translateX(-30%);
      }
    }
  }
}

// ** //

// PICTURES
.vod-slider__pictures {
  position: relative;

  @include mq(l) {
    z-index: 10;
    display: block;
    width: col(5);
    padding-right: $spacing;
  }
}

.vod-slider__speedlines {
  position: absolute;
  top: 10rem;
  left: 50%;
  width: 150%;
  height: 100%;
  transform: translateX(-55%);

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.vod-slider__slider-outer {
  position: relative;

  @include mq('l') {
    height: 100%;
  }
}

.vod-slider__slider {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;

  @include mq($until: l) {
    margin: $spacing 0 $spacing * 2;
  }
}

.vod-slider__item {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.5s $ease;

  &.is-active {
    opacity: 1;
  }

  .vod-slider__tooltip {
    @include mq('xl') {
      display: block;
    }
  }
}

.vod-slider__item-inner {
  width: 100%;
  // max-width: 30rem;

  @include mq('l') {
    max-width: 40rem;
  }
}

.vod-slider__item__category {
  @extend %fw-light;

  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1;

  @include mq(l) {
    font-size: 2.4rem;
  }
}

.vod-slider__item__title {
  @extend %fw-bold;

  margin-top: 40px;
  font-family: $ff-alt;

  @include mq(l) {
    margin-top: $spacing / 2;
    font-size: 3.2rem;
  }
}

::v-deep {
  .vod-slider__item__picture {
    @include aspect-ratio(3, 4);

    position: relative;
    z-index: 11;
    max-width: 20rem;
    margin: 0 auto;

    background-color: $c-light-gray;

    .slidy-nav & {
      max-width: 17.5rem;
    }

    img {
      @extend %box-shadow;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include mq('l') {
      max-width: 40rem;
    }
  }
}

.vod-slider__item__link {
  @include get-all-space;

  z-index: 1;
}

// INFOS
.vod-slider__infos {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // max-width: 30rem;

  @include mq(xs) {
    max-width: 40rem;
    margin: 0 auto;
  }

  @include mq(l) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: col(7);
    max-width: none;
    text-align: left;
  }
}

.voo-slider__title {
  @extend %fw-medium;

  margin: 2rem 0;
  text-align: center;

  @include mq('l') {
    margin: 10rem 0 2rem;
    font-size: 4.8rem;
    text-align: left;
  }
}

.voo-slider__text {
  text-align: center;

  @include mq('l') {
    text-align: left;
  }
}

.voo-slider__label {
  margin-bottom: 8px;
}

.voo-slider__btn {
  display: table;
  margin: 0 auto;
  text-align: center;

  @include mq(l) {
    align-self: flex-start;
    margin: 0;
  }
}

.vod-slider__controls {
  display: none;

  .vod-slider__pictures & {
    @include mq($until: 'l') {
      display: block;
    }
  }

  .vod-slider__infos & {
    @include mq('l') {
      display: block;
    }
  }
}

.vod-slider .slidy-controls {
  position: absolute;
  z-index: 12;
  top: 50%;
  left: 0;
  display: flex;
  flex-direction: column;

  @include mq($until: 'l') {
    width: 100%;
  }

  @include mq('l') {
    top: auto;
    bottom: $spacing * 3;
    left: 0;
  }
}

// SLIDY
.vod-slider [class*='slidy-controls__item--'] {
  svg {
    background-color: $c-light;
    border: 2px solid $c-anthracite-light;
    fill: $c-anthracite-light;
  }

  &:hover {
    svg {
      background-color: $c-anthracite-light !important; //sass-lint:disable-line no-important
      fill: $c-light;
    }
  }
}

.vod-slider .slidy-controls__item--next {
  margin-top: 1rem;

  @include mq($until: m) {
    right: -0.8rem;
  }
}

.vod-slider .slidy-controls__item--prev {
  @include mq($until: m) {
    left: -0.8rem;
  }
}

.vod-slider__tooltip {
  position: absolute;
  z-index: 100;
  top: 24rem;
  right: 4rem;
  display: none;
  padding: $spacing * 0.25 $spacing;
  color: $white;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  background: $c-pink-medium;
  border-radius: 8px;

  strong {
    display: block;
    font-size: 2.2rem;
  }

  &::before {
    @include center-y;

    content: '';
    top: 50%;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: $c-pink-medium;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.vod-slider ::v-deep {
  .slidy-nav__item {
    order: 2;
    opacity: 1;
    transition: opacity 0.5s 0.2s ease-out;

    &:first-child {
      display: none;
    }

    &.is-viewed {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }

  .slidy-nav__item button {
    width: 17.5rem;
    height: 23.4rem;
  }

  .slidy-nav__item button,
  .slidy-nav__item.is-active button {
    background: none;
    border: 0;
    box-shadow: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
