
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
/* vue:scoped */
.hub-tv,
[class*='hub-tv--'] {
  ::v-deep {
    .centered-block-outer {
      @include mq($until: l) {
        margin-bottom: 0;
      }
    }

    .hero__slides__item__picture-outer,
    [class*='hero__slides__item__picture-outer--'] {
      background-color: $c-blue-dark;
    }

    .hero__slides__item-inner {
      justify-content: center;
      text-align: center;
    }

    .hero__slides__item__content {
      width: auto;
      margin-top: $spacing * 5;

      [class*='btn--'][class*='--big'] {
        margin-top: $spacing * 2;

        .btn__link__title {
          font-size: 1.8rem;
        }
      }

      @include mq($until: l) {
        margin: auto;
        padding-top: 3rem;
      }
    }

    .hero__slides__item__icon {
      display: flex;
      justify-content: center;
      margin-bottom: $spacing * 1.5;

      // .icon {
      //   height: 7.2rem;
      //   max-height: none;
      // }

      @include mq($until: l) {
        margin-top: $spacing * 0.5;
      }
    }

    .hero__slides__item__title {
      font-size: 5rem;

      @include mq($until: m) {
        font-size: 3rem;
      }
    }

    .hub__card-small {
      background-color: $white !important;

      .card-small__title {
        @extend %text-center;

        max-width: 65rem;
        margin: 0 auto;
      }

      .card-small__description {
        @extend %text-center;

        max-width: 65rem;
        margin: $spacing * 2 auto;
      }

      .card-small__items__item {
        margin-bottom: $spacing * 2;

        @include mq(l) {
          margin-bottom: $spacing * 4;
        }
      }
    }

    .cards-grid__list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      li {
        width: 30%;
        margin: 0 col(1) / 6 $spacing * 2;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .compatible-devices + .section-row__content__btn {
      margin-top: $spacing * 1.5;
    }
  }
}

.centered-block-outer {
  ::v-deep {
    .centered-block {
      border-radius: 8px;

      @include mq($until: l) {
        transform: none;
      }
    }

    .cards-grid__list li {
      &:first-child {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: $spacing * 5;
      }
    }

    .cta__excerpt {
      max-width: 58rem;
    }
  }
}

.hub-tv__devices {
  color: $white;
  background-color: $c-blue-dark;

  ::v-deep {
    li::marker {
      color: white;
    }
  }
}

// .hub-tv__gift {
//   ::v-deep {
//     .cta__icon {
//       fill: $c-pink-medium;
//       width: 6rem;
//       height: 6rem;
//     }

//     .cta__excerpt {
//       max-width: 64rem;

//       @include mq(l) {
//         font-size: 1.8rem;
//       }
//     }

//     .btn__link__title {
//       @include mq(l) {
//         font-size: 1.8rem;
//       }
//     }
//   }
// }

// .hub-tv__gift__logos {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: $spacing * 2 0 0;

//   div {
//     margin: 0 $spacing * 1.5;
//   }

//   svg {
//     &:first-child {
//       fill: $black;
//     }
//   }

//   @include mq(l) {
//     padding: $spacing * 3.5 0 $spacing;

//     img {
//       height: 5rem;
//     }
//   }
// }

.compatible-devices__label {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
}

.compatible-devices__logos {
  display: flex;
}

.compatible-devices__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $spacing * 2.25;

  .icon {
    height: 3rem;
    margin-bottom: $spacing / 2;
    fill: $white;
    transition: fill 0.2s $ease-out-quart;
  }

  .text {
    color: $white;
    font-size: 1.1rem;
    font-weight: 900;
    transition: color 0.2s $ease-out-quart;
  }

  &:hover {
    .icon {
      fill: $c-pink-medium;
    }

    .text {
      color: $c-pink-medium;
    }
  }
}

.vod-slider {
  padding-top: 0;
  padding-bottom: 0;
  background-color: $c-white-dark;

  ::v-deep {
    .voo-slider__title {
      margin-top: 0;
      margin-bottom: 1.6rem;
      font-weight: 700;
    }

    .voo-slider__btn {
      margin-top: 1.6rem;
    }
  }
}
