
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.channels-slider-outer {
  padding-top: 0 !important;
  overflow: hidden;
}

.channels-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.channels-slider__content {
  position: relative;
  z-index: 9;
  padding: 10rem 15rem;

  @include mq($until: m) {
    padding: 0;
  }

  &::after {
    @include center-xy;

    z-index: -1;
    content: '';
    display: block;
    height: 100%;
    width: 100vw;

    @include mq(m) {
      background: linear-gradient(
        to right,
        rgba($white, 0.05) 0%,
        rgba($white, 1) 20%,
        rgba($white, 1) 80%,
        rgba($white, 0.05) 100%
      );
    }

    @include mq(xl) {
      background: linear-gradient(
        to right,
        rgba($white, 0.05) 20%,
        rgba($white, 1) 35%,
        rgba($white, 1) 65%,
        rgba($white, 0.05) 80%
      );
    }

    @include mq(xxl) {
      background: linear-gradient(
        to right,
        rgba($white, 0.05) 0%,
        rgba($white, 1) 25%,
        rgba($white, 1) 75%,
        rgba($white, 0.05) 100%
      );
    }
  }
}

.channels-slider__title {
  max-width: 68rem;
}

.channels-slider__text {
  max-width: 54rem;
  margin-left: auto;
  margin-right: auto;
}

.channels-slider__list-outer {
  // @include center-xy;

  @include mq(m) {
    @include center-xy;

    z-index: -1;
    left: -40%;
    transform: translate(-50%, -50%) perspective(500px) rotateY(20deg);
  }

  @include mq(xl) {
    left: -15%;
  }

  @include mq(xxl) {
    transform: translate(-50%, -50%) perspective(750px) rotateY(20deg);
  }

  &.is-right {
    display: none;
    left: 140%;

    @include mq(m) {
      display: block;
      transform: translate(-50%, -50%) perspective(500px) rotateY(-20deg);
    }

    @include mq(xl) {
      left: 115%;
      // transform: translate(-50%, -50%) perspective(500px) rotateY(-20deg);
    }

    @include mq(xxl) {
      transform: translate(-50%, -50%) perspective(750px) rotateY(-20deg);
    }
  }
}

.channels-slider__list {
  overflow: hidden;
  padding: 0 0 $spacing;
}

// !TODO IMPROVE
.channels-list {
  position: relative;
  display: flex;
  width: fit-content;
  transform: translate3d(0, 0, 0);
  margin: 2rem;
  animation-play-state: running;
  animation: marquee-reverse 90s linear infinite;
  --move-final-reverse: calc((14rem + 2rem) * 20);

  &:nth-child(2n + 1) {
    animation: marquee 90s linear infinite;
    --move-final: calc((14rem + 2rem) * -20);
  }
  .is-reverse & {
    animation: marquee 90s linear infinite;
    --move-final: calc((14rem + 2rem) * -20);

    &:nth-child(2n + 1) {
      animation: marquee-reverse 90s linear infinite;
      --move-final: calc((14rem + 2rem) * -20);
    }
  }

  @include mq($until: m) {
    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

.channels-list-inner {
  display: flex;
}

.channels-list__item {
  display: flex;
  align-items: center;
  width: 14rem;
  margin: 0 1rem;
  padding: 2rem;
  border: 1px solid $c-gray-lightest;
  border-radius: 3px;
}

// !TODO IMPROVE
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final-reverse), 0, 0);
  }
}

.channels-slider__link-outer {
  margin-top: $spacing;
}

.compatible-devices__label {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
}

.compatible-devices__logos {
  display: flex;
  justify-content: center;
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 2;
}

.compatible-devices__logo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $spacing * 2;

  .icon {
    height: 3rem;
    margin-bottom: $spacing / 2;
    transition: fill 0.2s $ease-out-quart;
  }

  .text {
    font-size: 1.1rem;
    font-weight: 900;
    transition: color 0.2s $ease-out-quart;
  }
}
