
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































































































































































































































































































































































.generosity-slider-outer {
  @extend %slider-legacy;
}

.generosity-slider,
[class*='generosity-slider--'] {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 80rem;
  background-color: $c-promo-card;

  @include mq(l) {
    height: 80rem;
  }

  ::v-deep {
    .slidy-controls {
      top: 50%;
      width: 100% !important;
      height: 5rem;
      transform: translateY(-50%);

      @include mq(l) {
        display: none;
      }
    }

    [class*='slidy-controls__item--'] {
      display: block;
      margin-top: 0;
      width: 5rem;
      height: 5rem;

      svg {
        padding: 1rem;
      }

      svg g {
        fill: white;
      }

      &:hover {
        svg g {
          @include mq(l) {
            fill: $c-anthracite-light;
          }
        }
      }
    }

    [class*='slidy-controls__item--'][class*='--next'] {
      right: 1rem;
    }

    [class*='slidy-controls__item--'][class*='--prev'] {
      left: 1rem;
    }
  }
}

.generosity-slider__title {
  @extend %fw-normal;

  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 5;

  color: $white;
  font-family: $ff-alt;
  font-size: 2.4rem;
  text-align: center;

  strong {
    @extend %fw-bold;
  }

  @include mq(l) {
    top: 10rem;
    font-size: 4.8rem;
    text-align: left;
  }
}

.generosity-slider__item {
  user-select: none;
  overflow: hidden;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: $c-promo-card;
  transition: all 0.3s $ease-out-quad;

  // filter: drop-shadow(-20px 50px 100px rgba(0, 0, 0, 0.5));

  @include mq($until: l) {
    display: flex;
    flex-direction: column;
  }

  @include mq(l) {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      width: 19rem;
      height: 19rem;

      border-style: solid;
      border-width: 0 0 19rem 19rem;
      border-color: transparent transparent $c-pink-medium transparent;
    }
  }
}

.generosity-slider__item__picture-outer {
  pointer-events: none;
  z-index: -1;
  background-color: $c-promo;

  &::after {
    @include get-all-space;

    content: '';
    background: linear-gradient(
      90deg,
      $c-promo-card 10%,
      rgba(8, 23, 40, 0) 56.75%
    );
  }

  @include mq($until: l) {
    position: relative;
    width: 100%;
    height: 30rem;
  }

  @include mq(l) {
    @include get-all-space;
  }
}

.generosity-slider__item__picture {
  @include image-fit(cover, $position: 100% 50%);

  @include mq($until: l) {
    @include image-fit(cover, $position: 75% 50%);
  }
}

.generosity-slider__item-inner {
  padding-top: $spacing * 3;
  width: 100%;
  height: 100%;

  @include mq($until: l) {
    // margin-top: auto;
    height: auto;
    text-align: center;

    background: $c-promo-card;
  }
}

.generosity-slider__item__label,
.generosity-slider__item__title,
.generosity-slider__item__description {
  @extend %fw-semi;

  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  @include mq(l) {
    max-width: 50%;
  }

  .is-active & {
    opacity: 1;
  }
}

.generosity-slider__item__label {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 2.4rem;
  padding: 1rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.2;
  background-color: $c-pink-medium;
  border-radius: 0.8rem;

  ::v-deep strong {
    display: block;
    font-size: 2.4rem;
  }

  @include mq(l) {
    margin-bottom: 4.4rem;
    padding: 1.2rem 1.8rem;
    font-size: 1.4rem;
  }
}

.generosity-slider__item__title {
  margin-top: 0;
  margin-bottom: 2.6rem;
  font-size: 3.2rem;
  line-height: 1.2;

  &.head {
    margin-bottom: 5rem;
    padding: 0 3rem;

    @include mq(l) {
      margin-bottom: 10rem;
    }
  }

  @include mq(l) {
    font-size: 4.8rem;
  }
}

.generosity-slider__item__description {
  font-size: 1.8rem;
  line-height: 1.6;

  @include mq($until: l) {
    max-width: 90%;
    margin-inline: auto;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }
}

.generosity-slider__item__link {
  margin-bottom: $spacing * 3;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &:hover {
    color: white !important;

    &:before {
      background-color: white;
    }
  }

  .is-active & {
    opacity: 1;
  }

  @include mq(l) {
    margin-top: $spacing * 3;
    margin-bottom: 0;
  }
}

.generosity-slider__item__number {
  @extend %fw-bold;

  z-index: 5;
  position: absolute;
  bottom: 3rem;
  right: 3rem;

  font-family: $ff-alt;
  color: white;
  font-size: 3.6rem;

  @include mq($until: l) {
    display: none;
  }
}
