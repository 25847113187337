
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























































































































































































































.hub-tv-extra {
  background-color: $c-blue-dark;
}

.hub-tv-extra__title {
  margin-top: 0;
  color: $white;
  font-weight: 100;
}

.hub-tv-extra__intro {
  margin-top: $spacing * 2;
  max-width: 80rem;
  color: $white;
  font-size: 2.4rem;
}

.hub-tv-extra__items {
  margin-top: $spacing * 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 4rem;

  @include mq(m) {
    margin-top: $spacing * 5;
    flex-direction: row;
    justify-content: center;
  }
}

.hub-tv-extra__items__title {
  @extend %fw-medium;
  @extend %text-center;

  margin: $spacing * 2 auto $spacing * 3;
  color: $white;
  font-family: $ff-alt;
  font-size: 3.6rem;
  max-width: 80rem;

  @include mq('m') {
    margin: $spacing * 5 auto $spacing * 4;
    font-size: 4.8rem;
  }
}

.hub-tv-extra__item {
  position: relative;
  z-index: 1;
  flex-basis: calc(33% - 4rem);
  min-height: 40rem;
  // background: linear-gradient(
  //   180deg,
  //   rgba($white, 1) 30%,
  //   $c-blue-dark 70%,
  //   $c-blue-dark 100%
  // );

  // background: linear-gradient(180deg, rgba(8, 23, 40, 0.25) 0%, #081728 100%);

  transition: filter 0.2s ease-out;

  &:hover {
    filter: drop-shadow(0px 50px 100px rgba(#1c3f67, 0.5));
  }

  &:nth-child(1),
  &:nth-child(2) {
    flex-basis: calc(50% - 4rem);
    height: 60rem;

    .hub-tv-extra__item__logo-outer {
      max-width: 10rem;

      img {
        width: 10rem;
        height: auto;
      }
    }
  }

  &:nth-child(2) {
    .hub-tv-extra__item__logo-outer {
      img {
        width: 8rem;
      }
    }
  }

  &:nth-child(3) {
    .hub-tv-extra__item__logo-outer {
      img {
        width: 12rem;
      }
    }
  }
}

.hub-tv-extra__item__picture-outer {
  @include get-all-space;

  overflow: hidden;
  z-index: -1;
  // opacity: 0.8;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    z-index: 1;
    // background: linear-gradient(180deg, rgba($white, 0) 30%, $c-blue-dark 100%);
    background: linear-gradient(
      180deg,
      rgba(8, 23, 40, 0.25) 0%,
      $c-promo-card 100%
    );
  }
}

.hub-tv-extra__item__picture {
  @include image-fit;

  transition: transform 0.6s ease-out;

  .hub-tv-extra__item:hover &,
  .hub-tv-extra__secondary__item:hover & {
    transform: scale(1.03);
  }
}

.hub-tv-extra__item__link {
  @include get-all-space;

  z-index: 9;
}

.hub-tv-extra__item__content {
  @include center-x;

  bottom: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $white;
}

.hub-tv-extra__item__logo-outer {
  max-width: 11rem;

  img {
    width: 11rem;
  }
}

.hub-tv-extra__item__label {
  margin-top: $spacing;
}

.hub-tv-extra__item__title {
  @extend %text-center;

  width: 24rem;
  margin: 0;
  margin-top: 2.6rem;
  font-size: 2.4rem;
}

.hub-tv-extra__secondary {
  margin-top: $spacing * 3;

  @include mq(l) {
    margin-top: $spacing * 8;
  }
}

.hub-tv-extra__secondary__title {
  @extend %fw-light;
  @extend %text-center;

  max-width: 80rem;
  margin: 0 auto;
  color: $white;

  strong {
    @extend %fw-bold;
  }
}

.hub-tv-extra__secondary__item {
  position: relative;
  z-index: 1;
  flex-basis: calc(33% - 4rem);
  min-height: 40rem;
  background: linear-gradient(
    180deg,
    rgba($white, 1) 30%,
    $c-blue-dark 70%,
    $c-blue-dark 100%
  );
  transition: filter 0.2s ease-out;

  &:hover {
    filter: drop-shadow(0px 50px 100px rgba(#1c3f67, 0.4));
  }
}

// DEvices
.compatible-devices__label {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
}

.compatible-devices__logos {
  display: flex;
  margin-top: $spacing * 2;
}

.compatible-devices__logo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $spacing * 2;

  // &:first-child {
  //   &::after {
  //     @include center-y;

  //     content: '';
  //     display: block;
  //     width: 1px;
  //     height: 80%;
  //     right: -$spacing;
  //     background-color: $white;
  //   }
  // }

  .icon {
    height: 3rem;
    margin-bottom: $spacing / 2;
    fill: $white;
    transition: fill 0.2s $ease-out-quart;
  }

  .text {
    color: $white;
    font-size: 1.1rem;
    font-weight: 900;
    transition: color 0.2s $ease-out-quart;
  }
}
